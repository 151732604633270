<template>
  <div class="top-info">
    <div class="content">
      <Row>
        <Col span="12">
        <div class="link">
          <Poptip content="content" placement="bottom-start" trigger="hover" width="170">
            <!-- <div class="item" @click="$refs.showMobile.open()"> -->
            <div class="item"><i class="icon iconfont icon-shouji3" style="padding-right: 2px"></i>手机版</div>
            <div class="my-show" slot="content">
              <div class="qrcode" id="qrcodehome" ref="qrCodeUrl"></div>
              <p style="text-align: center; margin-top: 10px">用微信扫描二维码</p>
            </div>
          </Poptip>
          <div @click="linkToExApply" class="item" v-if="enabledExhibitorApply">企业入驻</div>
          <div @click="linkToExLogin" class="item">展商登录</div>
          <!-- <div @click="linkToRegSteps" class="item" v-if="!isRegEx">参观预登记</div> -->
        </div>
        </Col>
        <Col span="12">
        <div class="link right">
          <div @click="$router.push(`/home/index/${exhibitionId}`)" class="item" v-if="isUser">
            <span>返回首页</span>
          </div>
          <div class="item txt" v-if="exhibitionInfo">
            <span class="txt1">
              {{ {startData:exhibitionInfo.startData,endDate:exhibitionInfo.endDate} | end_time }}
            </span>
          </div>
          <div class="item" v-if="!login||!user">
            <span @click="goLogin" class="txt1" style="padding-right: 5px">你好，请登录</span>
          </div>
          <div @click="linkToRegStepsHandle" class="item" v-if="!login||!user">
            <span>免费注册</span>
          </div>

          <Dropdown class="my-down" placement="top-end" v-else>
            <div :class="{ active: visible }" class="item" v-if="user">{{ user.name || user.nickName || "未命名用户" }}</div>
            <DropdownMenu slot="list" v-if="user">
              <div class="mc-info">
                <div class="logo">
                  <img :src="user.avatarUrl" v-if="user.avatarUrl" />
                  <img src="@/assets/img/user.png" v-else />
                </div>
                <div class="info-content">
                  <p>
                    <span class="txt1">{{ user.name || user.nickName || "未命名用户" }}</span>
                    {{ user.jobTitle }}
                  </p>
                  <p>{{ user.company }}</p>
                </div>
              </div>
              <div class="mc-icon">
                <div class="icon-item">
                  <div @click="goToMyPage('/user/inquiry')" class="icon-1">
                    <div class="icon-1-box green">
                      <i class="icon iconfont icon-xunpan"></i>
                    </div>
                    <p>我的询盘</p>
                  </div>
                  <div @click="goToMyPage('/user/need')" class="icon-1" v-if="enabledSupplyDemand">
                    <div class="icon-1-box theme2">
                      <i class="icon iconfont icon-jiaoyi1"></i>
                    </div>
                    <p>我的供需</p>
                  </div>
                  <div @click="goToMyPage('/user/follow')" class="icon-1">
                    <div class="icon-1-box theme3">
                      <i class="icon iconfont icon-heart"></i>
                    </div>
                    <p>我的关注</p>
                  </div>
                  <div @click="goToMyPage('/user/order')" class="icon-1">
                    <div class="icon-1-box theme4">
                      <i class="icon iconfont icon-dongtai1"></i>
                    </div>
                    <p>我的预约</p>
                  </div>
                </div>
              </div>
              <div class="mc-btn">
                <span @click="
                  $router.push(`/user/index?exhibitionId=${exhibitionId}`);
                  visible = false;
                ">个人中心</span>
                <span @click="loginOut">退出</span>
              </div>
            </DropdownMenu>
          </Dropdown>
          <div @click="sendMsg" class="item" v-if="login && user">
            消息通知
            <span class="brand" v-if="msgCount">{{ msgCount }}</span>
          </div>
          <div @click="linkToLang()" class="item" v-if="enabledSwitchLanguage">
            <svg class="icon-svg icon-min" aria-hidden="true" style="margin-right:5px;">
              <use xlink:href="#icon-uk"></use>
            </svg>
            En
          </div>
        </div>
        </Col>
      </Row>
    </div>
    <showMobile ref="showMobile" v-if="exhibitionId"></showMobile>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import showMobile from "@components/showMobile";
import config from "@assets/config.json";
import QRCode from "qrcodejs2";
import url from "@/utils/url";

export default {
  name: "top-info",
  props: {
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      url: url,
      visible: false,
      msgCount: 0,
    };
  },
  components: {
    showMobile,
  },
  computed: {
    ...mapGetters({
      loginType: "getLoginType",
      user: "getUser",
      login: "getLogin",
      token: "getToken",
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionConfig: "getExhibitionConfig",
    }),
    isRegOk () {
      return this.user && this.user.inMember;
    },
    isRegEx () {
      // 判断是否注册当前展会
      return (
        this.user &&
        this.user.inMember &&
        this.user.inMember.userRole != "VISITOR" &&
        this.user.inMember.userRole != "Visitor"
      );
    },
    enabledSwitchLanguage () {
      let bool = false;
      if (this.exhibitionConfig && this.exhibitionConfig.feature) {
        bool = this.exhibitionConfig.feature.enabledSwitchLanguage || false;
      }
      return bool;
    },
    enabledSupplyDemand () {
      return (
        this.exhibitionConfig &&
        this.exhibitionConfig.feature &&
        this.exhibitionConfig.feature.enabledSupplyDemand
      );
    },
    enabledExhibitorApply () {
      return (
        this.exhibitionConfig &&
        this.exhibitionConfig.feature &&
        this.exhibitionConfig.feature.enabledExhibitorApply
      );
    },
    pcRegLink () {
      return (
        this.exhibitionConfig &&
        this.exhibitionConfig.visitorReg &&
        this.exhibitionConfig.visitorReg.pcRegLink
      );
    },
  },
  created () {
    this.getMsgCount();
    this.getExhibitionConfig();
  },
  mounted () {
    this.$Bus.$off("refreshMsg");
    this.$Bus.$on("refreshMsg", () => {
      this.getMsgCount();
    });
    this.getQrCode();
  },
  methods: {
    ...mapMutations({
      setLoginType: "getLoginType",
      setRedirectUrl: "setRedirectUrl",
      setUser: "setUser",
      setUserId: "setUserId",
      setMyAdvisorySetting: "setMyAdvisorySetting",
      setToken: "setToken",
      setTokenTime: "setTokenTime",
      setLogin: "setLogin",
    }),
    ...mapActions({
      signOutOidc: "signOutOidc",
      signOutOidcQQ: "oidcStoreQQ/signOutOidc",
      graphqlPost: "graphqlPostByZXS",
      unverifiedDialog: "unverifiedDialog",
      linkToRegSteps: "linkTo/linkToRegSteps",
      linkToLogin: "linkTo/linkToLogin",
      linkToRegForm: "linkTo/linkToRegForm",
      getExhibitionConfig: "loginModule/getExhibitionConfig",
    }),
    goLogin () {
      this.setLogin(false);
      this.setUser("");
      this.setUserId("");
      this.setMyAdvisorySetting(null);
      this.setToken("");
      this.setTokenTime("");
      this.$router.push(`/login/${this.exhibitionId}`);
    },
    linkToExApply () {
      // if (this.exhibitionConfig && this.exhibitionConfig.feature && this.exhibitionConfig.feature.enabledExhibitorApply && this.exhibitionConfig.feature.exhibitorApplyUrl) {
      //   // 如果后台设置展商入驻的链接 直接跳转
      //   location.href = this.exhibitionConfig.feature.exhibitorApplyUrl;
      //   return;
      // }
      // this.$Message.info("暂未开放");
      // 企业入驻
      location.href = `${config.pcUrl}/ex-live/settled?id=${this.exhibitionId}`;
    },
    switchLang () {
      console.log(this.$i18n.locale);
      let lang = this.$i18n.locale === "zh" ? "en" : "zh";
      this.$i18n.locale = lang;
      this.setLang(lang);
    },
    linkToLang () {
      location.href = `https://${this.url.pcEn}/home/index/${this.exhibitionId}`;
    },
    getQrCode () {
      let qrcodUrl = `https://${this.url.mobile}/home/${this.exhibitionId}`;
      let qrcode = new QRCode("qrcodehome", {
        width: 130,
        height: 130,
        text: qrcodUrl, // 二维码内容
        correctLevel: 3,
      });
    },
    sendMsg () {
      if (!this.isRegOk) {
        this.unverifiedDialog(this);
        return;
      }
      this.goToMyPage("/user/msg");
    },
    async getMsgCount () {
      if (!(this.user && this.user.inMember)) return;
      let query = `
        query{
          notificationMessageQuery{
            unreadNumber(exhibitionId:"${this.exhibitionId}",receiverId:"${this.user.userId}")
          }
        }
      `;
      let opt = {
        query: query,
        variables: {},
      };
      let data = await this.graphqlPost(opt);
      let res = JSON.parse(
        JSON.stringify(data.data.notificationMessageQuery.unreadNumber)
      );
      if (res > 99) {
        res = "99+";
      }
      this.msgCount = res;
    },
    goToCard () {
      this.visible = false;
      if (
        this.user &&
        this.user.inMember &&
        this.user.inMember.userRole != "VISITOR" &&
        this.user.inMember.userRole != "Visitor"
      ) {
        this.$router.push(`/user/card?exhibitionId=${this.exhibitionId}`);
      } else {
        this.linkToRegSteps();
      }
    },
    goToMyPage (url) {
      if (
        url == "/user/need" ||
        url == "/user/order" ||
        url == "/user/inquiry"
      ) {
        if (this.user && this.user.inMember.isBlacklist) {
          this.$Message.warning("您已被屏蔽");
          return;
        }
      }
      this.visible = false;
      this.$router.push(`${url}?exhibitionId=${this.exhibitionId}`);
    },
    linkToRegStepsHandle() {
      if (this.pcRegLink) {
        window.open(this.pcRegLink)
      }else {
        this.linkToRegSteps();
      }
    },
    loginOut () {
      this.setRedirectUrl(location.href);
      this.setLogin(false);
      this.setUser("");
      this.setUserId("");
      this.setMyAdvisorySetting(null);
      this.setToken("");
      this.setTokenTime("");
      if (this.loginType === 2) {
        this.signOutOidc();
      } else if (this.loginType === 3) {
        this.signOutOidcQQ();
      } else {
        if (this.isUser) {
          this.$router.push(`/home/index/${this.exhibitionId}`);
        } else {
          location.reload();
        }
      }
    },
    linkToExLogin () {
      window.open(`${config.pcUrl}/ex-live/login/ex?id=${this.exhibitionId}`);
    },
    linkToLogin2 () {
      const that = this;
      if (this.token) {
        if (this.user && this.user.userId && this.user.phone) {
          this.linkToRegForm({
            type: "next",
            phone: this.user.phone,
          });
        } else {
          this.linkToRegSteps();
        }
      } else {
        this.$Modal.confirm({
          title: "未登录用户",
          content: "请前往登录再进行操作",
          okText: "前往登录",
          onOk: () => {
            that.linkToLogin();
          },
          onCancel: () => { },
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
::v-deep {
  .ivu-poptip-body {
    padding: 14px;
    .my-show {
      img {
        margin: 0 auto;
      }
    }
  }
}

.top-info {
  height: 30px;
  font-size: 12px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e6e6e6;
  .content {
    width: 1200px;
    margin: 0 auto;
  }
  .link {
    height: 30px;
    display: flex;
    align-items: center;
    &.right {
      justify-content: flex-end;
    }
    .item {
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 9px;
      position: relative;
      cursor: pointer;
      &.txt {
        &:hover {
          color: inherit;
        }
      }
      &.active {
      }
      &:hover {
        color: #3e76f6;
      }
      &::before {
        position: absolute;
        top: 5px;
        right: 0;
        content: "";
        display: inline-block;
        height: 18px;
        width: 1px;
        background: #ddd;
      }
      &:last-child {
        &::before {
          background: transparent;
        }
      }
      .txt1 {
        color: #ff5e00;
      }
      .brand {
        background: #ff5e00;
        color: #fff;
        display: inline-block;
        min-width: 16px;
        height: 16px;
        line-height: 16px;
        padding: 0 4px;
        @include background_color(#ff5e00);
        border-radius: 8px;
        text-align: center;
        font-size: 10px;
        margin-left: 7px;
        transform: scale(0.9);
      }
    }
  }
}
.my-down {
  &:hover {
    background-color: #fff;
    box-shadow: 0px 1px 5px 0px rgba(116, 116, 116, 0.2);
  }
  ::v-deep {
    .ivu-select-dropdown {
      margin: 0;
      padding: 0;
      border-radius: 0;
      width: 280px;
      min-height: 196px;
      background-color: #fff;
      box-shadow: 0px 1px 5px 0px rgba(116, 116, 116, 0.2);
    }
  }
  .mc {
    &-info {
      display: flex;
      padding: 14px 26px 14px 14px;
      align-items: center;
      border-bottom: 1px solid #f2f2f2;
      .logo {
        width: 42px;
        height: 42px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .info-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-size: 12px;
        color: #666;
        padding-left: 9px;
        flex: 1;
        p {
          width: 100%;
        }
        .txt1 {
          font-size: 14px;
          color: #333;
          padding-right: 5px;
        }
        .txt2 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 189px;
        }
      }
    }
    &-icon {
      border-bottom: 1px solid #f2f2f2;
      .icon-item {
        padding: 10px 9px;
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        .icon-1 {
          &:hover {
            p {
              color: #3e76f6;
            }
          }
          p {
            font-size: 12px;
            color: #666;
            margin-top: 8px;
          }
          &-box {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            color: #fff;
            &.green {
              background-color: #ff86a3;
            }
            &.theme2 {
              background-color: #ba92ff;
            }
            &.theme3 {
              background-color: #6cbeff;
            }
            &.theme4 {
              background-color: #68ddf9;
            }
            .iconfont {
              font-size: 20px;
            }
          }
        }
      }
    }
    &-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 14px;
      font-size: 12px;
      height: 32px;
      color: #666;
      span {
        cursor: pointer;
        &:hover {
          color: #1c8ff8;
        }
      }
    }
  }
}

// .my-down:hover{
//     ::v-deep {
//       .ivu-select-dropdown {
//         display: block !important;
//         transform-origin: center top;
//         position: absolute;
//         will-change: top, left;
//         top: 30px;
//         left: 231px;
//       }
//     }
// }
</style>
