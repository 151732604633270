<template>
  <div class="copyright">
    <div class="my-tab" v-if="enabledExhibitionList">
      <div class="my-tab-top">
        <div :class="{ active: active === index }" :key="'exhibitionList-' + index" :title="item.shortName || item.name || item.nameEn" @click="active = index" class="item" v-for="(item, index) in exhibitionList">
          {{ item.displayName || item.shortName || item.name || item.nameEn }}
        </div>
      </div>
      <div class="my-tab-bottom" v-if="exhibitionList && exhibitionList.length">
        <template v-for="(item, index) in exhibitionList">
          <div :key="'exhibition-' + index" @click="linkTo(item.id)" class="container" v-if="active === index">
            <div class="m-listc">
              <div class="ml3-left">
                <img :src="item.banner" />
              </div>
              <div class="ml3-right">
                <div class="ml3-right-top">
                  <span :title="item.name" class="ml3-txt">{{ item.name }}</span>
                </div>
                <div class="ml3-right-bottom">
                  <p>
                    时间：{{ item.startData | moment("YYYY/MM/DD") }}
                    ~
                    {{ item.endDate | moment("YYYY/MM/DD") }}
                  </p>
                  <p>地点：{{ item.address }}</p>
                  <p v-html="item.reStr"></p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="copyright-info" v-if="isCac">
      <a href="javaScript:;" v-if="exhibitionConfig && exhibitionConfig.keepRecord">{{ exhibitionConfig.keepRecord }}</a>
      &nbsp;
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102001043">京公网安备11010102001043号</a>
      &nbsp;
      <a target="_blank" href="https://beian.miit.gov.cn">京ICP备17065506号-22</a>
    </div>
    <div class="copyright-info" v-else>
      <a href="javaScript:;" v-if="exhibitionConfig && exhibitionConfig.keepRecord">{{ exhibitionConfig.keepRecord }}</a>
      <div class="copyright-info" v-else>
        Copyright ©
        {{ year }}
        zhanshangxiu.com All Rights Reserved.
        <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备09006318号-13</a>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402008791" target="_blank">
          <img src="https://www.zhanshangxiu.com/content/images/beian.png" style="vertical-align: middle; height: 16px;" />
          沪公网安备 31011402008791号
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "copyright",
  data () {
    return {
      active: 0,
      exhibitionList: [], // 品牌展商列表
      year: "",
      isCac: false,
    };
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      exhibitionInfo: "getExhibitionInfo",
      exhibitionConfig: "getExhibitionConfig",
      enabledExhibitionList: "getEnabledExhibitionList",
    }),
    exId () {
      return this.$route.query;
    },
    exhibitionOption () {
      let query = `
        query($query: _Any!) {
          exhibitionQuery {
            page(query: $query) {
              items {
                id
                name
                nameEn
                shortName
                shortNameEn
                displayName
                showIndex
                address
                logo
                remark
                startData
                endDate
                banner
              }
            }
          }
        }   
      `;
      let opt = {
        query: query,
        variables: {
          query: {
            where: {
              equal_hostId: {
                n: "hostId",
                v: this.exhibitionInfo.hostId,
              },
            },
            size: 5,
            order: [
              {
                n: "showIndex",
                v: 0,
              },
            ],
          },
        },
      };
      return opt;
    },
    // isCac () {
    //   return window.location.hostname.indexOf('cacshowonline') !== -1
    // }
  },
  created () {
    this.exhibitionInit();
    this.year = moment(new Date()).format("YYYY");
    if (window.location && window.location.hostname.indexOf('cacshowonline') !== -1) {
      this.isCac = true; 
    }
  },
  methods: {
    ...mapActions({
      graphqlPost: "graphqlPostByZXS",
    }),
    async exhibitionInit () {
      let res = await this.graphqlPost(this.exhibitionOption);
      if (res.data && res.data.exhibitionQuery && res.data.exhibitionQuery.page) {
        let arr = res.data.exhibitionQuery.page;
        arr.items.map((c, idx) => {
          if (this.exhibitionId === c.id) {
            this.active = idx;
          }
          let reStr = '';
          if (c.remark && c.remark.indexOf('[') !== -1) {
            let arr = JSON.parse(c.remark);
            let str = '';
            arr.forEach(el => {
              if (el.text) {
                if (str) {
                  str += '<br />' + el.text.content
                } else {
                  str = el.text.content;
                }
              }
            });
            c.reStr = str;
          } else if (c.remark) {
            c.reStr = c.remark;
          } else {
            c.reStr = '';
          } 

          // if (c.banners[0]) {
          //   c.covers = c.banners[0].covers;
          // }
          // if (!c.covers) {
          //   c.covers = c.logo;
          // }
        });
        this.exhibitionList = arr.items; 
        for (let i = 0; i < this.exhibitionList.length; i++) {
          if (this.exhibitionList[i.name] == this.exhibitionInfo.name) {
            this.active = i
            break;
          }
        }
      }
    },
    linkTo (id) {
      window.open(location.origin + "/home/index/" + id);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@assets/scss/my-theme/mixin.scss";
.copyright {
  width: 100%;
  color: #aaa;
  font-size: 12px;
  text-align: center;
  background-color: #373d44;
  z-index: 1;
  padding-top: 17px;
  p {
    opacity: 0.7;
    margin: 0;
  }
}
.copyright-info {
  font-size: 12px;
  color: #aaa;
  line-height: 26px;
  padding: 19px 0;
  a {
    color: #aaa;
  }
}
.my-tab {
  width: 1200px;
  margin: 0 auto;
  text-align: left;
  &-top {
    display: flex;
    .item {
      height: 54px;
      border-radius: 10px 10px 0px 0px;
      line-height: 54px;
      text-align: center;
      flex: 1;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      color: #fff;
      font-weight: bold;
      padding: 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover,
      &.active {
        background-color: #49515b;
      }
      &::before {
        content: "";
        position: absolute;
        right: -1px;
        top: 50%;
        margin-top: -13px;
        width: 1px;
        height: 26px;
        background-color: #49515b;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
  &-bottom {
    height: 230px;
    .container {
      height: 230px;
      .empty {
        width: 100%;
        line-height: 230px;
        text-align: center;
        color: #888;
        font-size: 14px;
      }
    }
  }
  ::v-deep {
    .m-listc {
      width: 100%;
      display: flex;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      padding: 30px;
      height: 230px;
      background-color: #49515b;
      color: #fff;

      &:hover {
        .ml3-txt {
          @include font_color(#1890ff);
        }
      }

      &:nth-child(even) {
        margin-right: 0;
      }

      .ml3-left {
        position: relative;
        overflow: hidden;
        width: 300px;
        height: 170px;
        border-radius: 6px;
        margin-right: 20px;

        img {
          display: block;
          height: 100%;
          width: 100%;
        }
      }

      .ml3-right {
        line-height: 1.3;
        flex: 1;
        overflow: hidden;

        &-top {
          display: flex;
          align-items: center;
          font-size: 14px;
        }

        &-bottom {
          margin-top: 6px;
          font-size: 14px;
          color: #333;

          p {
            margin-top: 8px;
            color: #fff;
            font-size: 12px;
            line-height: 1.4;
          }
        }
      }

      .ml3-txt {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        white-space: nowrap;
        margin-right: 14px;
      }
    }
  }
}
</style>
